<!-- 充值活动 -->
<template>
    <div class="charge-activity-container">
        <HeaderComp class="activity-header" :title="rechargeConfig.activityName" @return="backTo" />
        <template v-if="isOver">
            <!-- 活动下线 -->
            <div class="activity-over">
                <img class="cover" src="https://f2.kkmh.com/game/210728/YcLJvGU8b.png" />
                <div class="main-title">活动已结束</div>
                <div class="sub-title">敬请期待其他活动</div>
            </div>
        </template>
        <template v-else>
            <!-- 活动进行中 -->
            <img v-if="activityBg" class="activity-bg" :src="activityBg | compress(750)" />
            <div class="activity-ing">
                <div class="time" :class="{ 'hide-time': hideTime }">
                    活动时间：{{ rechargeConfig.startTime | formatDate('yyyy-MM-dd hh:mm') }}至{{
                        rechargeConfig.endTime | formatDate('yyyy-MM-dd hh:mm')
                    }}
                </div>
                <h2 class="info">
                    充值{{ rechargeValue }}元
                    <br />
                    多得{{ presentValue }}书币
                    <br />
                    共得{{ totalValue }}书币
                    <br />
                </h2>
                <!-- 隐藏点击区域 -->
                <div class="click-area" @click="doCharge()"></div>
                <div class="tips">
                    温馨提示：
                    <br />
                    1.本平台人民币与书币兑换比率：1元={{ exchangeRate }}书币
                    <br />
                    2.充值活动赠送书币有效期5天
                    <br />
                    3.十六周岁以下未成年在本平台进行任何活动需获得家长同意或法定监护人的同意
                    <br />
                    4.购买服务成功后，平台即时收取费用，虚拟产品不提供退货服务，敬请谅解
                    <br />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import HeaderComp from '@/components/header/index.vue';
import { formatDate } from '@/modules/tools.js';
import { query } from '@kk/utils';
import { ChargeUtils } from '@/modules/charge.js';

export default {
    name: 'ChargeActivity',
    components: {
        HeaderComp,
    },
    computed: {
        ...mapGetters('activity', ['canJoinAct']),
        ...mapState('activity', ['rechargeConfig']),
        ...mapState(['userInfo']),
        activityBg() {
            return this.rechargeConfig.activityImg;
        },
        showBack() {
            // history只增不减，无法判断是否存在上一个页面
            return !/setck/.test(document.referrer);
        },
    },
    filters: {
        formatDate,
    },
    data() {
        const activityId = Number(sessionStorage.getItem('kk-recharge-activity') || 0);
        let isFromRechargeActivity = false;
        let hideTime = query.get('hideOnlineTime') || 'false';
        hideTime = JSON.parse(hideTime);

        if (activityId) {
            isFromRechargeActivity = true;
            sessionStorage.setItem('kk-recharge-activity', 0);
        }
        return {
            hideTime,
            isOver: false,
            isFromRechargeActivity,
            prePage: '',
            totalValue: 0,
            rechargeValue: 0,
            presentValue: 0,
            exchangeRate: 100,
            hasHistory: false,
        };
    },
    async created() {
        const isContinuePay = sessionStorage.getItem('kk-activity-continue-pay');
        const productId = sessionStorage.getItem('kk-activity-product-id');

        if (isContinuePay) {
            this.doCharge(productId);
            sessionStorage.removeItem('kk-activity-continue-pay');
            sessionStorage.removeItem('kk-activity-product-id');
        }
        document.title = '充值中心';
        try {
            const { data, code } = await this.$store.dispatch('activity/getRechargeActivityInfo');
            loading.hide();

            if (code === 20401) {
                // 活动结束错误码
                this.isOver = true;
                this.isFromRechargeActivity = true;
                return;
            }
            const { serviceTime, endTime, rechargeInfo } = data;
            this.isOver = serviceTime >= endTime;

            this.rechargeValue = rechargeInfo.rechargeValue / this.exchangeRate;
            this.presentValue = rechargeInfo.presentValue;
            this.totalValue = this.presentValue + this.rechargeValue * this.exchangeRate;
        } catch (err) {
            loading.hide();
        }
    },
    mounted() {
        const PrePage = this.$route.params.from ? this.$route.params.from : '首页';
        this.sa('CommonPageOpen', {
            uid: this.$store.state.userInfo.uid,
            CurPage: '充值活动页',
            PrePage,
        });
    },
    methods: {
        backTo() {
            if (this.hasHistory) {
                this.$router.back();
                return;
            }
            this.$router.replace({
                name: 'Index',
            });
        },
        async doCharge(productId) {
            // 判断是否可充值
            let err = '';
            const { serviceTime, endTime } = this.rechargeConfig;
            if (serviceTime >= endTime) {
                err = '活动到期';
            } else if (!productId && !this.canJoinAct) {
                // 已经获取 openid 后，才进行判断
                err = '活动参与次数用尽，请关注下次活动';
            }
            if (err) return this.$toast.show(err);

            this.sa('CommonBtnClk', {
                uid: this.userInfo.uid,
                CurPage: '充值活动页',
                ButtonName: '立即充值',
            });
            this.confirmCharge()
                .then(async () => {
                    const chargeUtils = new ChargeUtils({
                        comicId: 0, //int 漫画id
                        topicId: 0, //int 专题id
                        goodId: this.rechargeConfig.productId || productId,
                        goodType: this.curChargeTarget.good_type,
                    });
                    try {
                        await chargeUtils.charge();
                    } catch (error) {
                        this.$toast.show('充值失败，请稍后再试～');
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.hasHistory = from.name;
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.activity-header.page-header {
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #fff;
}
.charge-activity-container {
    width: 100%;
    min-height: 100vh;
    text-align: center;
    color: #fff;

    .activity-over {
        margin-top: 175px;
        color: #f9b4b4;

        .cover {
            display: block;
            margin: auto;
            width: 91px;
            height: 125px;
        }
        .main-title {
            margin-top: 12px;
            font-size: 16px;
        }
        .sub-title {
            margin-top: 3px;
            font-size: 14px;
        }
    }
    .activity-bg {
        display: block;
        width: 100vw;
    }
    .activity-ing {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .time {
            margin-top: .convert(315px) [ @vw];
            font-size: 12px;
            line-height: 15px;

            &.hide-time {
                visibility: hidden;
            }
        }
        .info {
            margin: 20px auto 12px;
            font-size: 28px;
            line-height: 45px;
        }
        .click-area {
            margin: 0 auto;
            width: 90%;
            height: .convert(400px) [ @vw];
        }
        .tips {
            position: absolute;
            bottom: 48px;
            padding: 0 16px;
            font-size: 11px;
            text-align: left;
            line-height: 18px;
        }
    }
}
</style>
